import {Defaults} from "@/core/constants/defaults";

export class StringHelper {
    public static ConcatWithСomma(values: string[]): string {
        return values.join(", ");
    }

    public static IsNotNullOrEmpty(value: string | null | undefined): boolean {
        return value != null && value.trim() !== Defaults.EmptyString;
    }

    public static capitalizeFirstLetter(value: string) {
        return value.charAt(0).toUpperCase() + value.slice(1);
    }

    public static insert(index: number, source: string, data: string): string {
        if (index < 0) throw 'Invalid argument "index"!';
        if (index === 0) return data + source;

        return source.substring(0, index) + data + source.substring(index, source.length);
    }
}
