export class ConversationMessageSubjectSummaryBM {
    public constructor(
        bookingNumber: number,
        senderFirstName: string,
        senderLastName: string,
        senderIconUrl: string | null) {
        this.BookingNumber = bookingNumber;
        this.SenderFirstName = senderFirstName;
        this.SenderLastName = senderLastName;
        this.SenderIconUrl = senderIconUrl;
    }

    public BookingNumber: number;
    public SenderFirstName: string;
    public SenderLastName: string;
    public SenderIconUrl: string | null;
}
