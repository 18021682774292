import axios from "axios";
import {CountryBM} from "@/core/models/metadata/CountryBM";
import {MetadataService} from "@/core/services/metadata-service";
import {CountryId_Russia} from "@/core/constants/technical-defaults";
import {ApplicationEnvironmentService} from "@/core/services/application-environment-service";

export class IPGeocodingService {
    private readonly apiUrlForDevelopment = 'https://ip-api.io/json';
    private readonly apiUrlForProduction = 'https://ipwhois.pro/?key=D53bzV2jyUAyTtmd';

    private readonly metadataService = new MetadataService();

    public async getCurrentUserCountry(): Promise<CountryBM> {
        const apiUrl = ApplicationEnvironmentService.isProduction
            ? this.apiUrlForProduction
            : this.apiUrlForDevelopment;

        let countryCode = 'ru';
        try {
            const response = await axios.get<IIPGeolocationResponse>(apiUrl, {
                method: 'GET',
                responseType: 'json'
            });

            countryCode = response.data.country_code;
        } catch (exception) {
            console.log(exception);
        }

        if (!countryCode) {
            countryCode = 'ru';
        }

        const countries = await this.metadataService.ReadCountries();
        const countryWhereUserLocated = countries.find(c =>
            c.Code.toUpperCase() === countryCode.toUpperCase());

        if (!countryWhereUserLocated) {
            return countries.find(c => c.Id === CountryId_Russia)!;
        }

        return countryWhereUserLocated;
    }

}

export interface IIPGeolocationResponse {
    query: string;
    status: string;
    country: string;
    countryCode: string;
    country_code: string;
    region: string;
    regionName: string;
    city: string;
    zip: string;
    lat: number;
    lon: number;
    timezone: string;
    isp: string;
    org: string;
    as: string;
}
