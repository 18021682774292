import {AuthenticationDataBM} from "@/core/models/common/AuthenticationDataBM";
import {UserTypeEnum} from "@/generated/clients";
import {CurrentUserBM} from "@/core/models/identity/CurrentUserBM";
import {Guid} from "@/core/types/type-aliases";
import {CurrentUserPassportBM} from "@/core/models/identity/CurrentUserPassportBM";
import intersection from "lodash/intersection";

export class CurrentUserService {

    // Constants:
    private static readonly UserProfileLocalStorageKey = "UserProfile";
    private static readonly AuthenticationDataLocalStorageKey = "AuthenticationData";
    private static readonly ClientRoles = [UserTypeEnum.ServiceProvider, UserTypeEnum.ServiceConsumer];

    // Service data:
    private static userProfile: CurrentUserBM | null = null;
    private static authenticationData: AuthenticationDataBM | null = null;

    public get id(): Guid {
        return CurrentUserService.userProfile!.Id;
    }

    public get roles(): UserTypeEnum[] {
        return CurrentUserService.userProfile!.Roles;
    }

    public get activeRole(): UserTypeEnum {
        return CurrentUserService.userProfile!.ActiveRole;
    }

    public get isAuthenticated(): boolean {
        return CurrentUserService.authenticationData != null;
    }

    public get isClient(): boolean {
        if (!this.isAuthenticated) return false;
        return intersection(CurrentUserService.ClientRoles, this.roles).length > 0;
    }

    public get isServiceConsumer(): boolean {
        if (!this.isAuthenticated) return false;
        return this.profile.ActiveRole === UserTypeEnum.ServiceConsumer;
    }

    public get isServiceProvider(): boolean {
        if (!this.isAuthenticated) return false;
        return this.profile.ActiveRole === UserTypeEnum.ServiceProvider;
    }

    public get isSystemAdministrator(): boolean {
        if (!this.isAuthenticated) return false;
        return this.profile.ActiveRole === UserTypeEnum.SystemAdministrator;
    }

    public get isSystemManager(): boolean {
        if (!this.isAuthenticated) return false;
        return this.profile.ActiveRole === UserTypeEnum.SystemManager;
    }

    public get authorizationToken(): string | null {
        if (!this.isAuthenticated) return null;
        if (!CurrentUserService.authenticationData == null) return null;
        return CurrentUserService.authenticationData!.Token;
    }

    public get profile(): CurrentUserBM {
        return CurrentUserService.userProfile!;
    }

    public static initialize(): void {
        const userProfileSerialized = localStorage.getItem(this.UserProfileLocalStorageKey);
        const authenticationDataSerialized = localStorage.getItem(this.AuthenticationDataLocalStorageKey);

        if (userProfileSerialized == null || authenticationDataSerialized == null) return;

        const userProfileDeserialized = JSON.parse(userProfileSerialized) as CurrentUserBM;
        CurrentUserService.userProfile = new CurrentUserBM(
            userProfileDeserialized.Id,
            userProfileDeserialized.PublicUserId,
            userProfileDeserialized.ActiveRole,
            userProfileDeserialized.Roles,
            new CurrentUserPassportBM(
                userProfileDeserialized.Passport.FirstName,
                userProfileDeserialized.Passport.LastName,
                userProfileDeserialized.Passport.Patronymic,
                userProfileDeserialized.Passport.Gender,

                userProfileDeserialized.Passport.DateOfBirth != null
                    ? new Date(userProfileDeserialized.Passport.DateOfBirth)
                    : null,

                userProfileDeserialized.Passport.SeriesAndNumber,
                userProfileDeserialized.Passport.IssuerCode,
                userProfileDeserialized.Passport.IssueDate != null
                    ? new Date(userProfileDeserialized.Passport.IssueDate)
                    : null,
                userProfileDeserialized.Passport.ResidenceAddress
            ),
            userProfileDeserialized.Email,
            userProfileDeserialized.PhoneNumber,
            userProfileDeserialized.AvatarFileId,
            userProfileDeserialized.AvatarFilePath,
            userProfileDeserialized.About,
            userProfileDeserialized.PreferredTimeZoneId,
            userProfileDeserialized.ProficiencyLanguageIds,
            userProfileDeserialized.ShowOnlyFirstNameOnBookingObjectView
        );

        const authenticationDataDeserialized = JSON.parse(authenticationDataSerialized) as AuthenticationDataBM;
        CurrentUserService.authenticationData = new AuthenticationDataBM(
            authenticationDataDeserialized.Token
        )
    }

    public switchActiveRole(role: UserTypeEnum) {
        if (!CurrentUserService.ClientRoles.includes(role)) {
            throw 'Unable to switch active role to not-client role!';
        }

        CurrentUserService.userProfile!.ActiveRole = role;

        localStorage.setItem(
            CurrentUserService.UserProfileLocalStorageKey,
            JSON.stringify(CurrentUserService.userProfile));
    }

    public setAuthenticationData(authenticationData: AuthenticationDataBM): void {
        CurrentUserService.authenticationData = authenticationData;

        localStorage.setItem(
            CurrentUserService.AuthenticationDataLocalStorageKey,
            JSON.stringify(CurrentUserService.authenticationData));
    }

    public setData(profileData: CurrentUserBM, authenticationData: AuthenticationDataBM): void {
        CurrentUserService.userProfile = profileData;
        CurrentUserService.authenticationData = authenticationData;

        localStorage.setItem(
            CurrentUserService.UserProfileLocalStorageKey,
            JSON.stringify(CurrentUserService.userProfile));

        localStorage.setItem(
            CurrentUserService.AuthenticationDataLocalStorageKey,
            JSON.stringify(CurrentUserService.authenticationData));
    }

    public static logout(): void {
        CurrentUserService.userProfile = null;
        CurrentUserService.authenticationData = null;

        localStorage.removeItem(CurrentUserService.AuthenticationDataLocalStorageKey);
        localStorage.removeItem(CurrentUserService.UserProfileLocalStorageKey);
    }
}
