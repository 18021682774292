export class CookiePolicyService {
    private readonly StorageKey = 'IsCookiePolicyAccepted';

    public accept(): void {
        localStorage.setItem(this.StorageKey, '+');
    }

    public isAccepted(): boolean {
        const record = localStorage.getItem(this.StorageKey);
        return record != null;
    }
}
