<template>
    <div class="operation-errors">
        <div class="action-header">{{ action }}</div>
        <ol class="messages">
            <li v-for="(errorMessage, index) in errorMessages" v-bind:key="index">
                {{ errorMessage }}
            </li>
        </ol>
    </div>
</template>

<script lang="ts">

import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class OperationErrors extends Vue {
    @Prop({required: true, type: String}) action!: string;
    @Prop({required: true, type: Array, default: []}) errorMessages!: string[];

    public static $liveCreate(parent: Vue, action: string, errorMessages: string[]) {
        return parent.$createElement(OperationErrors, {
            props: {
                action: action,
                errorMessages: errorMessages
            }
        });
    }
}
</script>
