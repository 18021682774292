import {Vue} from "vue-property-decorator";
import VueI18n from "vue-i18n";
import VueRouter, {RawLocation} from "vue-router";
import {RouteNames} from "@/router/route-names";
import {
    BookingObjectCategoryEnum, BookingObjectParkingPaymentTypeEnum,
    BookingObjectPricePolicyReductionTypeEnum,
    BookingObjectRoomTypeEnum,
    BookingPaymentTypeEnum,
    BookingStatusEnum, UserTypeEnum
} from "@/generated/clients";
import {FillDatesTypeEnum} from "@/core/enums/fill-dates-type-enum";
import {BookingObjectPricePolicyWorkModeEnum} from "@/core/enums/booking-object-price-policy-work-mode-enum";
import {GlobalOverlayService} from "@/core/services.ui/global-overlay-service";
import {FillDatesDialogModeEnum} from "@/core/enums/fill-dates-dialog-mode-enum";
import {DeviceChecker} from "@/helpers/device-checker";
import {BookingPriceBuildingStateEnum} from "@/core/enums/booking-price-building-state-enum";
import {BookingObjectViewModeEnum} from "@/core/enums/booking-object-view-mode-enum";
import {$Event_SynchronizeBookingsCountToConfirm, EventNames} from "@/core/constants/event-names";
import {LayoutService} from "@/core/services.ui/layout-service";
import {CallbackAsync} from "@/core/utils/functional-utils";
import OperationErrors from "@/core/components.business/OperationErrors.vue";
import {BookingReviewManagementModeEnum} from "@/core/enums/booking-review-management-mode-enum";
import {
    CountryRegionCityManagementMode
} from "@/views/administration/address-metadata-management/country-region-city-management/models/country-region-city-management-mode";
import {ArticleBuilderModeEnum} from "@/core/components.business/article-builder/models/content-structure";
import {ManageArticleModeEnum} from "@/views/administration/content-management.article-management/models/manage-article-mode.enum";
import {Guid} from "@/core/types/type-aliases";
import {CurrentUserService} from "@/core/services/current-user-service/current-user-service";
import {YandexMetricService} from "@/core/services/analitics/yandex-metric-service";

export class BaseComponent extends Vue {
    protected RouteNames = RouteNames;

    protected overlayService = new GlobalOverlayService();
    protected layoutService = new LayoutService();
    protected yandexMetricService = new YandexMetricService();

    protected Enums = {
        ViewModes: {
            ManageBookingObjectReview: BookingReviewManagementModeEnum,
            CountryRegionCityManagementMode: CountryRegionCityManagementMode,
            ArticleBuilderMode: ArticleBuilderModeEnum,
            ArticleManagementMode: ManageArticleModeEnum
        },

        BookingPaymentTypes: BookingPaymentTypeEnum,
        BookingStatus: BookingStatusEnum,
        FillDatesType: FillDatesTypeEnum,
        BookingObjectPricePolicyWorkMode: BookingObjectPricePolicyWorkModeEnum,
        FillDatesDialogMode: FillDatesDialogModeEnum,
        BookingObjectCategory: BookingObjectCategoryEnum,
        BookingObjectPricePolicyReductionType: BookingObjectPricePolicyReductionTypeEnum,
        BookingObjectRoomType: BookingObjectRoomTypeEnum,
        BookingPriceBuildingState: BookingPriceBuildingStateEnum,
        BookingObjectParkingPaymentType: BookingObjectParkingPaymentTypeEnum,
        BookingObjectViewMode: BookingObjectViewModeEnum,
        UserRoles: UserTypeEnum

    }

    protected EventNames = EventNames;

    public $localize(expression: string) {
        return this.$t(expression);
    }

    public $localizeInterpolative(expression: string, values?: VueI18n.Values) {
        return this.$t(expression, values);
    }

    public $localizeDateTime(expression: Date, formatName: string) {
        return this.$d(expression, formatName);
    }

    protected showErrors(errorMessages: string[], errorTitle = 'Ошибка') {
        for (const errorMessage of errorMessages) {
            this.$bvToast.toast(errorMessage, {
                title: errorTitle,
                variant: 'danger',
                solid: true
            });
        }
    }

    protected get isMobileDevice(): boolean {
        return DeviceChecker.isMobile();
    }

    protected synchronizeBookingsCountToConfirm() {
        this.$root.$emit($Event_SynchronizeBookingsCountToConfirm);
    }

    protected redirectToNotFound() {
        this.$router.push({name: RouteNames.PageNotFound});
    }

    protected redirectToUnauthorized() {
        this.$router.push({name: RouteNames.UnauthorizedRequest});
    }

    protected errorNotification(errorMessages: string[]) {
        const operationErrorsComponent = OperationErrors.$liveCreate(this,
            'Произошла ошибка при выполнении операции',
            errorMessages);

        this.$bvToast.toast(operationErrorsComponent, {
            title: `Ошибка при удалении отзыва`,
            variant: 'danger',
            solid: true
        });
    }

    protected successNotification(message: string) {
        this.$bvToast.toast(message, {
            title: `Информация`,
            variant: 'success',
            solid: true
        });
    }

    protected async withAsyncLoadingContext(callback: CallbackAsync, loadingText: string | null = null): Promise<void> {
        this.overlayService.Loading(loadingText);
        try {
            await callback();
        } finally {
            this.overlayService.Completed();
        }
    }

    protected showNotImplementedAlert() {
        alert("Функциональность еще не реализована!");
    }

    protected openRoute(routeName: string) {
        this.$router.push({name: routeName});
    }
}
