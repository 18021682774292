import {ExchangeRatesClient} from "@/generated/clients";
import {BaseUrlProvider} from "@/core/providers/base-url-provider";
import {AxiosInstanceProvider} from "@/core/providers/axios-instance-provider";
import {ExchangeRateBM} from "@/core/models/exchange-rates/ExchangeRateBM";
import {Subject, Subscription} from "rxjs";

export interface ExchangeRatesChangingSubscriptionData {
    (rates: ExchangeRateBM[]): void;
}

export class ExchangeRatesService {

    // Services:
    private static readonly client = new ExchangeRatesClient(
        BaseUrlProvider.getBaseUrl(),
        AxiosInstanceProvider.Get());

    // Constants:
    public static readonly ExchangeRatesActualizationPeriod = 10 * 60 * 1000;

    // Service data:
    private static cachedExchangeRates: ExchangeRateBM[] = [];
    private static $onExchangeRatesChangedEventSource = new Subject<ExchangeRateBM[]>();
    private static isActualizationLocked = false;

    private static async actualizeExchangeRates() {
        if (ExchangeRatesService.isActualizationLocked) return;

        const response = await this.client.readActual();
        ExchangeRatesService.cachedExchangeRates = response.map(dto => ExchangeRateBM.createFromDTO(dto));
        ExchangeRatesService.$onExchangeRatesChangedEventSource.next(ExchangeRatesService.cachedExchangeRates);
    }

    public static async initialize() {

        // Actualize:
        await ExchangeRatesService.actualizeExchangeRates();

        // Start periodical job:
        setInterval(
            async () => await ExchangeRatesService.actualizeExchangeRates(),
            ExchangeRatesService.ExchangeRatesActualizationPeriod);
    }

    public static readActual(): ExchangeRateBM[] {
        return ExchangeRatesService.cachedExchangeRates;
    }

    public static lockActualization() {
        this.isActualizationLocked = true;
    }

    public static unlockActualization() {
        this.isActualizationLocked = false;
    }

    public static subscribeOnChange(handler: ExchangeRatesChangingSubscriptionData): Subscription {
        return ExchangeRatesService.$onExchangeRatesChangedEventSource.subscribe(handler);
    }
}
