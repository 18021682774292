import {CountryVDTO} from "@/generated/clients";
import {CountryRegionBM} from "@/core/models/metadata/CountryRegionBM";

export class CountryBM {
    public constructor(
        id: number,
        name: string,
        code: string,
        currencyId: number,
        countryRegions: CountryRegionBM[]) {
        this.Id = id;
        this.Name = name;
        this.Code = code;
        this.CurrencyId = currencyId;
        this.Regions = countryRegions;
    }

    public Id: number;
    public Name: string;
    public Code: string;
    public CurrencyId: number;
    public Regions: CountryRegionBM[];

    public static createFromDTO(dto: CountryVDTO): CountryBM {
        return new CountryBM(
            dto.Id!,
            dto.Name!,
            dto.Code!,
            dto.CurrencyId!,
            dto.Regions!.map(r => CountryRegionBM.createFromDTO(r))
        );
    }
}
