import {Guid} from "@/core/types/type-aliases";
import {ExchangeRateVDTO} from "@/generated/clients";

export class ExchangeRateBM {

    public constructor(
        id: Guid,
        dateTimeCreated: Date,
        targetCurrencyId: number,
        nominal: number,
        value: number) {

        this.Id = id;
        this.DateTimeCreated = new Date(dateTimeCreated);
        this.TargetCurrencyId = targetCurrencyId;
        this.Nominal = nominal;
        this.Value = value;
    }

    public Id: Guid;
    public DateTimeCreated: Date;
    public TargetCurrencyId: number;
    public Nominal: number;
    public Value: number;

    public static createFromDTO(dto: ExchangeRateVDTO): ExchangeRateBM {
        return new ExchangeRateBM(
            dto.Id!,
            dto.DateTimeCreated!,
            dto.TargetCurrencyId!,
            dto.Nominal!,
            dto.Value!
        );
    }
}
