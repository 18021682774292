export class TimeSpanVO {
    public constructor(stringRepresentation: string) {
        const sign = stringRepresentation[0] === '-' ? -1 : 1;

        let processing = `${stringRepresentation}`;
        if (stringRepresentation[0] === '-' || stringRepresentation[0] === '+') {
            processing = processing.substr(1);
        }

        const parts = processing.split(':');
        this.Hours = sign * Number(parts[0]);
        this.Minutes = sign * Number(parts[1]);
        this.Seconds = sign * Number(parts[2]);

        this.StringRepresentation = stringRepresentation;
    }

    public Hours: number;
    public Minutes: number;
    public Seconds: number;
    public StringRepresentation: string;

    public toShortString(): string {
        const preprocess = (timePart: number): string => {
            if (timePart < 10) return `0${timePart}`;
            return timePart.toString();
        }

        return `${preprocess(this.Hours)}:${preprocess(this.Minutes)}`;
    }
}
