import {CurrencyBM} from "@/core/models/metadata/CurrencyBM";
import {IPGeocodingService} from "@/core/services/ip-geocoding-service";
import {MetadataService} from "@/core/services/metadata-service";

export class ClientCurrencyProvider {

    // Defaults:
    private readonly StorageKey = 'ClientCurrencyId';

    // Services:
    private readonly ipGeocodingService = new IPGeocodingService();

    public async readOrDetect(): Promise<CurrencyBM> {
        const currencyIdFromStorageSerialized = localStorage.getItem(this.StorageKey);
        if (!currencyIdFromStorageSerialized) {
            const ipDetectedCountry = await this.ipGeocodingService.getCurrentUserCountry();
            const ipDetectedCurrency = MetadataService.currenciesMap.get(ipDetectedCountry.CurrencyId)!;

            this.upsert(ipDetectedCurrency.Id);
            return ipDetectedCurrency;
        }

        const currencyFromStorage = MetadataService.currenciesMap.get(Number(currencyIdFromStorageSerialized))!;
        return currencyFromStorage;
    }

    public upsert(currencyId: number) {
        localStorage.setItem(this.StorageKey, currencyId.toString());
    }
}
