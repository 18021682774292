import VueRouter from "vue-router";
import routeDefinitions from "@/router/route-definitions";
import {RequireAuthenticationGuard} from "@/router/guards/require-authentication-guard";
import {RequireRolesGuard} from "@/router/guards/require-roles-guard";
import {ScrollHelper} from "@/helpers/scroll-helper";

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes: routeDefinitions,
    scrollBehavior(to, from, savedPosition): any {
        ScrollHelper.scrollBaseViewToTop();
        return {x: 0, y: 0};
    }
});

router.beforeEach((to, from, next) => {
    if (!RequireAuthenticationGuard.checkAndProcess(to, from, next)) return;
    if (!RequireRolesGuard.checkAndProcess(to, from, next)) return;
})

export default router;
