<template>
    <b-modal modal-class="information-modal" ref="modal"
             v-bind:title="data.Title"
             v-bind:no-close-on-esc="data.AllowHideOnlyOnHideButtonClick"
             v-bind:no-close-on-backdrop="data.AllowHideOnlyOnHideButtonClick"
             centered>
        <p v-for="message in data.Messages">{{ message }}</p>

        <template #modal-footer="{ ok, cancel, hide }">
            <button @click="onOkButtonClick(cancel)" class="btn btn-success">
                {{ $localize("Components.InformationModal.Button_OK") }}
            </button>
        </template>
    </b-modal>
</template>

<script lang="ts">
import {BaseComponent} from "../BaseComponent";
import {Component, Prop, Ref} from "vue-property-decorator";
import {InformationModalData} from "@/core/components/InformationModal/models/information-modal-data";
import {BModal} from "bootstrap-vue";

@Component({
    components: {
        'b-modal': BModal
    }
})
export default class InformationModal extends BaseComponent {

    // Component references:
    @Ref("modal") modal!: BModal;

    // Component properties:
    @Prop({required: true, type: Object}) data!: InformationModalData;

    public show(): void {
        this.modal.show();
    }

    private onOkButtonClick(cancelCallback: Function): void {
        this.data.AfterFinishCallback?.();
        cancelCallback();
    }
}
</script>
