import {CurrentUserPassportDataResponseDTO, GenderAssignmentEnum} from "@/generated/clients";

export class CurrentUserPassportBM {
    public constructor(
        firstName: string,
        lastName: string,
        patronymic: string | null,
        gender: GenderAssignmentEnum,
        dateOfBirth: Date | null,
        seriesAndNumber: string | null,
        issuerCode: string | null,
        issueDate: Date | null,
        residenceAddress: string | null) {
        this.FirstName = firstName;
        this.LastName = lastName;
        this.Patronymic = patronymic;
        this.Gender = gender;
        this.DateOfBirth = dateOfBirth;
        this.SeriesAndNumber = seriesAndNumber;
        this.IssuerCode = issuerCode;
        this.IssueDate = issueDate;
        this.ResidenceAddress = residenceAddress;
    }

    public FirstName: string;
    public LastName: string;
    public Patronymic: string | null;
    public Gender: GenderAssignmentEnum;
    public DateOfBirth: Date | null;
    public SeriesAndNumber: string | null;
    public IssuerCode: string | null;
    public IssueDate: Date | null;
    public ResidenceAddress: string | null;

    public static createFromDTO(dto: CurrentUserPassportDataResponseDTO): CurrentUserPassportBM {
        return new CurrentUserPassportBM(
            dto.FirstName!,
            dto.LastName!,
            dto.Patronymic!,
            dto.Gender!,
            dto.DateOfBirth != null ? new Date(dto.DateOfBirth) : null,
            dto.SeriesAndNumber!,
            dto.IssuerCode!,
            dto.IssueDate != null ? new Date(dto.IssueDate) : null,
            dto.ResidenceAddress!
        );
    }
}
