<template>
    <div class="base-view">
        <slot/>
    </div>
</template>

<script lang="ts">

import {Component, Vue} from "vue-property-decorator";
import {ScrollTrackingService} from "@/core/services.ui/scroll-tracking-service";

@Component
export default class BaseView extends Vue {
    public mounted() {
        ScrollTrackingService.start();
    }

    public beforeDestroy() {
        ScrollTrackingService.stop();
    }
}
</script>
