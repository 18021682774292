import moment from "moment";
import {DateConstants} from "@/core/constants/date-constants";
import {TimeSpanHelper} from "@/helpers/TimeSpanHelper";
import {TimeSpanVO} from "@/core/value-objects/TimeSpanVO";
import {ArrayHelper} from "@/helpers/array-helper";

export class DateHelper {
    public static getCurrentYear(): number {
        return new Date().getFullYear();
    }

    public static areDatesEqual(dateA: Date, dateB: Date): boolean {
        if (!dateA || !dateB) return false;
        return dateA.getTime() === dateB.getTime();
    }

    public static ToDateFromUtc(dateUTCRepresentation: string | Date): Date {
        return moment.utc(dateUTCRepresentation).toDate();
    }

    public static ToLocalDateString(dateUTCRepresentation: string): string {
        const dateMoment = moment.utc(dateUTCRepresentation).local();
        return dateMoment.format(DateConstants.DateFormat_RU);
    }

    public static ToLocalDateTimeString(dateUTCRepresentation: string | Date): string {
        const dateMoment = moment.utc(dateUTCRepresentation).local();
        return dateMoment.format(DateConstants.DateTimeFormat_RU);
    }

    public static ToDateTimeNativeInputFormatString(dateUTCRepresentation: string | Date): string {
        const dateMoment = moment.utc(dateUTCRepresentation).local();
        return dateMoment.format(DateConstants.DateTimeFormat_NativeInput);
    }

    public static ParseDateTimeFromNativeInputFormatString(nativeDTInputString: string): Date {
        return moment(nativeDTInputString, DateConstants.DateTimeFormat_NativeInput).toDate();
    }

    public static ToDateString(date: Date | string): string {
        return moment(date).format(DateConstants.DateFormat_RU);
    }

    public static ToDateTimeString(date: Date): string {
        return moment(date).format(DateConstants.DateTimeFormat_RU);
    }

    public static ToDateInSpecificTimeZone(date: Date, offset: string): Date {
        const momentJsOffset = TimeSpanHelper.buildTimespanForMomentJS(offset);
        return moment(date.toDateString())
            .utcOffset(0, true)
            .utcOffset(momentJsOffset, true)
            .toDate();
    }

    /** Use for converting server DateTimeOffset to booking object timezone accordingly with client timezone. */
    public static toBookingObjectTimezone(utc: string | Date, bookingObjectTimezoneOffset: TimeSpanVO) {
        const invertedClientTimezoneOffset = -2 * new Date().getTimezoneOffset();

        const dateInBookingObjectTimezone = moment(utc, true)
            .add(bookingObjectTimezoneOffset.Hours, 'hours')
            .add(bookingObjectTimezoneOffset.Minutes, 'minutes')
            .utcOffset(invertedClientTimezoneOffset, true);

        return dateInBookingObjectTimezone.toDate();
    }

    public static ToMomentInSpecificTimeZoneFromUTC(utc: string, offset: string): moment.Moment {
        const momentJsOffset = TimeSpanHelper.buildTimespanForMomentJS(offset);
        return moment(utc)
            .utcOffset(0, true)
            .utcOffset(momentJsOffset, true);
    }

    public static DiffIn24H(start: Date, finish: Date): number {
        const momentR = moment(finish);
        const momentL = moment(start);

        const diffIn24H = Math.ceil(momentR.diff(momentL, 'hours') / 24);
        return diffIn24H;
    }

    public static getNextDate(date: Date): Date {
        const copy = new Date(date);
        copy.setDate(date.getDate() + 1);
        return copy;
    }

    public static getTodayDate(): Date {
        return new Date();
    }

    public static getTomorrowDate(): Date {
        const date = new Date();
        date.setDate(date.getDate() + 1);
        return date;
    }

    public static getTodayDateWithoutTime(): Date {
        return this.removeTime(new Date());
    }

    public static getTomorrowDateWithoutTime(): Date {
        const date = new Date();
        date.setDate(date.getDate() + 1);
        return this.removeTime(date);
    }

    public static getTomorrowNextDate(days = 1): Date {
        const date = DateHelper.getTomorrowDate();
        date.setDate(date.getDate() + days);
        return date;
    }

    public static removeTime(dateTime: Date) {
        return new Date(
            dateTime.getFullYear(),
            dateTime.getMonth(),
            dateTime.getDate()
        );
    }

    public static getPrevMonthDate(date: Date): Date {
        const newMonth = date.getMonth() - 1;
        if (newMonth < 0) return new Date(date.getFullYear() - 1, newMonth + 12, 1);
        return new Date(date.getFullYear(), newMonth, 1);
    }

    public static getNextYearDate(date: Date): Date {
        const temp = new Date(date);
        temp.setFullYear(temp.getFullYear() + 1);
        return temp;
    }

    public static getPrevYearDate(date: Date): Date {
        const temp = new Date(date);
        temp.setFullYear(temp.getFullYear() - 1);
        return temp;
    }

    public static getNextMonthDate(date: Date): Date {
        if (date.getMonth() == 11) return new Date(date.getFullYear() + 1, 0, 1);
        return new Date(date.getFullYear(), date.getMonth() + 1, 1);
    }

    public static getMonthAndYear(date: Date): string {
        let monthName = new Intl.DateTimeFormat('ru', {month: 'long'}).format(date);
        monthName = monthName[0].toUpperCase() + monthName.substr(1);
        return `${monthName} ${date.getFullYear()}`;
    }

    public static isDateInFuture(date: Date): boolean {
        const now = new Date();
        const result = moment.utc(now) <= moment.utc(date);
        return result;
    }

    public static isDateInPast(date: Date, todayIsPast = false): boolean {
        const now = new Date();
        if (this.isToday(date)) return todayIsPast;
        return moment.utc(date) < moment.utc(now);
    }

    public static isDateInInterval(date: Date, interval: Date[]) {
        const orderedInterval = ArrayHelper.SortBy(interval, v => v);
        return date >= orderedInterval[0] && date <= orderedInterval[1];
    }

    public static isDateIntervalInInterval(interval: Date[], intersectionWith: Date[]) {
        return intersectionWith[0] <= interval[0] && intersectionWith[1] >= interval[1];
    }

    public static dateWithoutTimeNow(): Date {
        const realNow = new Date();
        return new Date(
            realNow.getFullYear(), realNow.getMonth(), realNow.getDate(),
            0, 0, 0);
    }

    public static getFirstDayDateOfCurrentMonthWithoutTime(): Date {
        const now = new Date();
        return new Date(now.getFullYear(), now.getMonth(), 1);
    }

    public static formatToMMYYYY(date: Date) {
        return new Intl.DateTimeFormat('ru', {month: 'long', year: 'numeric'})
            .format(date);
    }

    public static isToday(date: Date) {
        return moment(date).isSame(new Date(), "day");
    }
}
