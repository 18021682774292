import {CountryRegionVDTO} from "@/generated/clients";
import {CountryRegionCityBM} from "@/core/models/metadata/CountryRegionCityBM";

export class CountryRegionBM {
    public constructor(
        id: number,
        name: string,
        cities: CountryRegionCityBM[]) {
        this.Id = id;
        this.Name = name;
        this.Cities = cities;
    }

    public Id: number;
    public Name: string;
    public Cities: CountryRegionCityBM[];

    public static createFromDTO(dto: CountryRegionVDTO): CountryRegionBM {
        return new CountryRegionBM(
            dto.Id!,
            dto.Name!,
            dto.Cities!.map(c => CountryRegionCityBM.createFromDTO(c))
        );
    }
}
