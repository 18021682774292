import {CountryRegionCityVDTO} from "@/generated/clients";
import {buildDefault} from "@/core/utils/functional-utils";

export class CountryRegionCityBM {
    public constructor(
        id: number,
        name: string,
        latitude: number,
        longitude: number,
        ianaTimeZoneId: string,
        ianaTimeZoneBaseUTCOffset: string,
        internalTimezoneId: number) {
        this.Id = id;
        this.Name = name;
        this.Latitude = latitude;
        this.Longitude = longitude;
        this.IANATimeZoneId = ianaTimeZoneId;
        this.IANATimeZoneBaseUTCOffset = ianaTimeZoneBaseUTCOffset;
        this.InternalTimezoneId = internalTimezoneId;
    }

    public Id: number;
    public Name: string;
    public Latitude: number;
    public Longitude: number;
    public IANATimeZoneId: string;
    public IANATimeZoneBaseUTCOffset: string;
    public InternalTimezoneId: number;

    public static createFromDTO(dto: CountryRegionCityVDTO): CountryRegionCityBM {
        return new CountryRegionCityBM(
            dto.Id!,
            dto.Name!,
            dto.Latitude!,
            dto.Longitude!,
            dto.IANATimeZoneId!,
            dto.IANATimeZoneBaseUTCOffset!,
            dto.InternalTimezoneId!
        );
    }

    public static createDefault(): CountryRegionCityBM {
        return new CountryRegionCityBM(
            buildDefault<number>(),
            buildDefault<string>(),
            buildDefault<number>(),
            buildDefault<number>(),
            buildDefault<string>(),
            buildDefault<string>(),
            buildDefault<number>());
    }
}
