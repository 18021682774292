import {CurrentUserDataResponseDTO, UserTypeEnum} from "@/generated/clients";
import {CurrentUserPassportBM} from "@/core/models/identity/CurrentUserPassportBM";
import {Guid} from "@/core/types/type-aliases";
import {buildDefault} from "@/core/utils/functional-utils";

export class CurrentUserBM {
    public constructor(
        id: Guid,
        publicUserId: Guid,
        activeRole: UserTypeEnum,
        roles: UserTypeEnum[],
        passport: CurrentUserPassportBM,
        email: string,
        phoneNumber: string,
        avatarFileId: Guid | null,
        avatarFilePath: string | null,
        about: string | null,
        preferredTimeZoneId: number | null,
        proficiencyLanguageIds: number[],
        showOnlyFirstNameOnBookingObjectView: boolean) {
        this.Id = id;
        this.PublicUserId = publicUserId;
        this.ActiveRole = activeRole;
        this.Roles = roles;
        this.Passport = passport;
        this.Email = email;
        this.PhoneNumber = phoneNumber;
        this.AvatarFileId = avatarFileId;
        this.AvatarFilePath = avatarFilePath;
        this.About = about;
        this.PreferredTimeZoneId = preferredTimeZoneId;
        this.ProficiencyLanguageIds = proficiencyLanguageIds;
        this.ShowOnlyFirstNameOnBookingObjectView = showOnlyFirstNameOnBookingObjectView;
    }

    public Id: Guid;
    public PublicUserId: Guid;
    public ActiveRole: UserTypeEnum;
    public Roles: UserTypeEnum[];
    public Passport: CurrentUserPassportBM;
    public Email: string;
    public PhoneNumber: string;
    public AvatarFileId: Guid | null;
    public AvatarFilePath: string | null;
    public About: string | null;
    public PreferredTimeZoneId: number | null;
    public ProficiencyLanguageIds: number[];
    public ShowOnlyFirstNameOnBookingObjectView: boolean;

    public static createFromDTO(dto: CurrentUserDataResponseDTO): CurrentUserBM {
        return new CurrentUserBM(
            dto.UserId!,
            dto.PublicUserId!,
            dto.MainRole!,
            dto.RoleIds!,
            CurrentUserPassportBM.createFromDTO(dto.Passport!),
            dto.Email!,
            dto.PhoneNumber!,
            dto.AvatarFileId!,
            dto.AvatarFilePath!,
            dto.About!,
            dto.PreferredTimeZoneId!,
            dto.ProficiencyLanguageIds!,
            dto.ShowOnlyFirstNameOnBookingObjectView!
        );
    }
}
