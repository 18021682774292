import * as signalR from '@microsoft/signalr';
import {Subject, Subscription} from 'rxjs';
import {BaseUrlProvider} from "@/core/providers/base-url-provider";
import {CurrentUserService} from "@/core/services/current-user-service/current-user-service";
import {JsonHubProtocol} from "@microsoft/signalr";
import {ConversationMessageVDTO, ConversationVDTO} from "@/generated/clients";
import {ConversationMessageBM} from "@/core/models/conversation/ConversationMessageBM";
import {Guid} from "@/core/types/type-aliases";
import {ConversationMessageSubjectSummaryBM} from "@/core/models/conversation/ConversationMessageSubjectSummaryBM";
import {ConversationBM} from "@/core/models/conversation/ConversationBM";
import {Action} from "@/core/utils/functional-utils";
import {ConversationSubjectSummaryBM} from "@/core/models/conversation/ConversationSubjectSummaryBM";

export class OnConversationCreatedEvent {
    public constructor(conversation: ConversationBM, conversationSubject: ConversationSubjectSummaryBM) {
        this.Conversation = conversation;
        this.ConversationSubject = conversationSubject;
    }

    public Conversation: ConversationBM;
    public ConversationSubject: ConversationSubjectSummaryBM;
}

export class OnConversationUpdatedEvent {
    public constructor(message: ConversationMessageBM, subjectSummary: ConversationMessageSubjectSummaryBM | null) {
        this.ConversationMessage = message;
        this.SubjectSummary = subjectSummary;
    }

    public ConversationMessage: ConversationMessageBM;
    public SubjectSummary: ConversationMessageSubjectSummaryBM | null;
}

export class OnConversationMessagesReadEvent {
    public constructor(userReadBy: Guid, conversationId: Guid, conversationMessageIds: Guid[]) {
        this.UserReadBy = userReadBy;
        this.ConversationId = conversationId;
        this.ConversationMessageIds = conversationMessageIds;
    }

    public UserReadBy: Guid;
    public ConversationId: Guid;
    public ConversationMessageIds: Guid[];
}

export class ConversationSocket {
    private static readonly $onConversationCreatedEventSource = new Subject<OnConversationCreatedEvent>();
    private static readonly $onConversationUpdatedEventSource = new Subject<OnConversationUpdatedEvent>();
    private static readonly $onConversationMessagesReadEventSource = new Subject<OnConversationMessagesReadEvent>();

    private static connection: signalR.HubConnection;

    private static setupConnection() {
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(`${BaseUrlProvider.getBaseUrl()}/hubs/conversation-socket`, {
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets,
                accessTokenFactory: () => new CurrentUserService().authorizationToken!
            })
            .withAutomaticReconnect()
            .withHubProtocol(new JsonHubProtocol())
            .configureLogging(signalR.LogLevel.Debug)
            .build();

        this.connection.on('conversation-created',
            (conversationVDTO: ConversationVDTO, subject: ConversationSubjectSummaryBM) => {
                const conversationBM = ConversationBM.createFromDTO(conversationVDTO);
                const conversationSubjectBM = new ConversationSubjectSummaryBM(
                    subject.BookingNumber,
                    subject.BookingObjectNumber,
                    subject.BookingObjectName,
                    subject.BookingObjectIconUrl
                );

                const event = new OnConversationCreatedEvent(conversationBM, conversationSubjectBM);
                this.$onConversationCreatedEventSource.next(event);
            });

        this.connection.on("conversation-message-created",
            (messageDTO: ConversationMessageVDTO, subjectSummary: ConversationMessageSubjectSummaryBM | null) => {
                const messageBM = ConversationMessageBM.createFromDTO(messageDTO);
                const subjectSummaryBM = subjectSummary == null ? null : new ConversationMessageSubjectSummaryBM(
                    subjectSummary.BookingNumber,
                    subjectSummary.SenderFirstName,
                    subjectSummary.SenderLastName,
                    subjectSummary.SenderIconUrl
                );

                const eventData = new OnConversationUpdatedEvent(messageBM, subjectSummaryBM);
                this.$onConversationUpdatedEventSource.next(eventData);
            });

        this.connection.on('conversation-messages-read', (event: OnConversationMessagesReadEvent) => {
            const eventData = new OnConversationMessagesReadEvent(
                event.UserReadBy,
                event.ConversationId,
                event.ConversationMessageIds
            );

            this.$onConversationMessagesReadEventSource.next(eventData);
        })
    }

    public static async initialize() {
        this.setupConnection();
        await this.connection.start();
    }

    public static async stop() {
        if (!this.connection) return;
        await this.connection.stop();
    }

    public static subscribeOnConversationCreate(
        handler: Action<OnConversationCreatedEvent>): Subscription {
        return this.$onConversationCreatedEventSource.subscribe(handler);
    }

    public static subscribeOnConversationUpdate(
        handler: Action<OnConversationUpdatedEvent>): Subscription {
        return this.$onConversationUpdatedEventSource.subscribe(handler);
    }

    public static subscribeOnConversationMessageReading(
        handler: Action<OnConversationMessagesReadEvent>): Subscription {
        return this.$onConversationMessagesReadEventSource.subscribe(handler);
    }
}
