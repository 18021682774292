import {RouteConfig} from "vue-router";
import {RouteNames} from "@/router/route-names";

const cmsRoutes: RouteConfig[] = [
    {
        path: "/cms/article/:key",
        name: RouteNames.CMS_ArticleHandler,
        component: () => import(/* webpackChunkName: "cms.article-handler" */ "../../views/cms/articles/CMSArticleHandler.vue"),
    }
];

export default cmsRoutes;
