import {Guid} from "@/core/types/type-aliases";
import {ConversationVDTO} from "@/generated/clients";

export class ConversationBM {
    public constructor(
        id: Guid,
        bookingId: Guid | null,
        dateTimeCreated: Date,
        dateTimeUpdated: Date,
        participantUserIds: Guid[]) {
        this.Id = id;
        this.BookingId = bookingId;
        this.DateTimeCreated = dateTimeCreated;
        this.DateTimeUpdated = dateTimeUpdated;
        this.ParticipantUserIds = participantUserIds;
    }

    public Id: Guid;
    public BookingId: Guid | null;
    public DateTimeCreated: Date;
    public DateTimeUpdated: Date;
    public ParticipantUserIds: Guid[];

    public static createFromDTO(dto: ConversationVDTO): ConversationBM {
        return new ConversationBM(
            dto.Id!,
            dto.BookingId!,
            new Date(dto.DateTimeCreated!),
            new Date(dto.DateTimeUpdated!),
            dto.ParticipantUserIds!
        );
    }
}
