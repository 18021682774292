import Vue from 'vue'
import App from './App.vue'
import store from './store'
import i18n from './i18n'

import VueRouter from "vue-router";
import router from "./router/router";
import VueYandexMetrika from "vue-yandex-metrika";
import {initializeApplication} from "@/main.init";
import BaseView from "@/core/components/BaseView.vue";
import {ModalPlugin, ToastPlugin} from 'bootstrap-vue'

import "./assets/styles/b24-app.scss";
import VueImg from "v-img";
import {YandexMetricsParameters} from "@/core/constants/yandex-metrics-parameters";

Vue.config.productionTip = false;
Vue.use(ModalPlugin);
Vue.use(ToastPlugin);
Vue.use(VueRouter);
Vue.use(VueImg);

Vue.use(VueYandexMetrika, {
    id: YandexMetricsParameters.CounterId,
    router: router,
    env: process.env.NODE_ENV,
    options: {
        clickmap:true,
        trackLinks:true,
        accurateTrackBounce:true,
        webvisor:true
    }
});

initializeApplication();

// Register layouts globally:
Vue.component('base-view', BaseView);

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
