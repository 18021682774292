<template>
    <div @click="onPopupClick" class="new-conversation-popup">
        <div class="subject">
            <div class="icon">
                <img v-bind:src="event.ConversationSubject.BookingObjectIconUrl"/>
            </div>

            <div class="data">
                <div class="booking">
                    Бронирование №{{ event.ConversationSubject.BookingNumber }}
                </div>
                <div class="booking-object">
                    №{{ event.ConversationSubject.BookingObjectNumber }}. {{ event.ConversationSubject.BookingObjectName }}
                </div>
            </div>
        </div>
        <div class="message mt-10">
            Для удобства коммуникации по бронированию была создана новая беседа.
            Теперь вы можете задать все интересующие вопросы.
        </div>
    </div>
</template>

<script lang="ts">

import {Component, Prop, Vue} from "vue-property-decorator";
import {BaseComponent} from "@/core/components/BaseComponent";
import {OnConversationCreatedEvent, OnConversationUpdatedEvent} from "@/core/services/conversation/conversation-socket";
import Avatar from "@/core/components.business/Avatar.vue";
import {DateHelper} from "@/helpers/date-helper";
import {ConversationManagementService} from "@/views/conversations/services/conversation-management-service";

@Component({
    components: {
        'user-icon': Avatar
    }
})
export default class NewConversationPopup extends BaseComponent {

    // Component properties:
    @Prop({required: true, type: Object}) event!: OnConversationCreatedEvent;

    private onPopupClick() {
        ConversationManagementService.openConversation(this.event.Conversation.Id);
        this.$bvToast.hide(`conversation-popup-${this.event.Conversation.Id}`);
    }

    public static $liveCreate(parent: Vue, event: OnConversationCreatedEvent) {
        return parent.$createElement(NewConversationPopup, {
            props: {
                event: event
            }
        })
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/views/conversations/new-conversation-popup.scss';
</style>
