import {RouteConfig} from "vue-router";
import {RouteNames} from "@/router/route-names";
import {buildRouteMetadata} from "@/router/models/route-metadata";
import {LayoutNames} from "@/core/constants/LayoutNames";
import {UserTypeEnum} from "@/generated/clients";

const occupancyScheduleRoutes: RouteConfig[] = [
    {
        path: '/profile/objects/:id/occupancy-schedule',
        name: RouteNames.BookingObjectManagement_OccupancySchedule,
        component: () => import(/* webpackChunkName: "profile.objects.occupancy-schedule"*/ "../../views/booking-object-occupancy-schedule/BookingObjectOccupancySchedule.vue"),
        meta: buildRouteMetadata({
            LayoutComponentName: LayoutNames.UserLayout,
            RequireAuthentication: true,
            RequireRoles: [
                UserTypeEnum.SystemAdministrator,
                UserTypeEnum.ServiceProvider
            ]
        })
    },
    {
        path: '/profile/objects/:id/occupancy-schedule/full',
        name: RouteNames.BookingObjectManagement_OccupancySchedule_ForBedding,
        component: () => import(/* webpackChunkName: "profile.objects.occupancy-schedule.full" */ "../../views/booking-object-occupancy-schedule-for-group/BookingObjectOccupancyScheduleForGroup.vue"),
        meta: buildRouteMetadata({
            LayoutComponentName: LayoutNames.UserLayout,
            RequireAuthentication: true,
            RequireRoles: [
                UserTypeEnum.SystemAdministrator,
                UserTypeEnum.ServiceProvider
            ]
        })
    },
    {
        path: '/profile/objects/:id/occupancy-schedule/apart',
        name: RouteNames.BookingObjectManagement_OccupancySchedule_ForApartment,
        component: () => import(/* webpackChunkName: "profile.objects.occupancy-schedule.apart" */ "../../views/booking-object-occupancy-schedule-for-group/BookingObjectOccupancyScheduleForGroup.vue"),
        meta: buildRouteMetadata({
            LayoutComponentName: LayoutNames.UserLayout,
            RequireAuthentication: true,
            RequireRoles: [
                UserTypeEnum.SystemAdministrator,
                UserTypeEnum.ServiceProvider
            ]
        })
    },
    {
        path: '/profile/objects/occupancy-schedule/for-user/:userId/full',
        name: RouteNames.BookingObjectManagement_OccupancySchedule_ForUser,
        component: () => import(/* webpackChunkName: "profile.objects.occupancy-schedule.for-user.full" */ "../../views/booking-object-occupancy-schedule-for-group/BookingObjectOccupancyScheduleForGroup.vue"),
        meta: buildRouteMetadata({
            LayoutComponentName: LayoutNames.UserLayout,
            RequireAuthentication: true,
            RequireRoles: [
                UserTypeEnum.SystemAdministrator,
                UserTypeEnum.ServiceProvider
            ]
        })
    },
];

export default occupancyScheduleRoutes;
