import axios, {AxiosInstance} from "axios";
import i18n from "@/i18n";
import {InformationMessagesService} from "@/core/services.ui/information-messages-service";
import {InformationModalData} from "@/core/components/InformationModal/models/information-modal-data";
import {GlobalOverlayService} from "@/core/services.ui/global-overlay-service";
import {LocalizationService} from "@/core/services/localization-service";
import {StringHelper} from "@/helpers/string-helper";
import {CurrentUserService} from "@/core/services/current-user-service/current-user-service";
import {AuthenticationDataBM} from "@/core/models/common/AuthenticationDataBM";
import {BaseUrlProvider} from "@/core/providers/base-url-provider";
import {AccountService} from "@/core/services/account/account-service";
import {LogoutOptionsBM} from "@/core/models/common/LogoutOptionsBM";

export class AxiosInstanceProvider {
    private static readonly informationMessagesService = new InformationMessagesService();
    private static readonly globalOverlayService = new GlobalOverlayService();
    private static readonly localizationService = new LocalizationService();
    private static readonly currentUserService = new CurrentUserService();

    private static instance: AxiosInstance | null = null;

    public static Get(): AxiosInstance {
        if (AxiosInstanceProvider.instance == null) AxiosInstanceProvider.Rebuild();
        return AxiosInstanceProvider.instance as AxiosInstance;
    }

    public static Rebuild(): void {
        AxiosInstanceProvider.instance = axios.create({
            baseURL: BaseUrlProvider.getBaseUrl(),
            headers: {
                'Accept-Language': AxiosInstanceProvider.localizationService.GetCurrentLocale()
            },
            transformRequest: data => data,
            transformResponse: data => data
        });

        AxiosInstanceProvider.addAuthorization(AxiosInstanceProvider.instance);
        AxiosInstanceProvider.addInterceptors(AxiosInstanceProvider.instance);
    }

    private static addAuthorization(instance: AxiosInstance): void {
        const token = AxiosInstanceProvider.currentUserService.authorizationToken;
        if (StringHelper.IsNotNullOrEmpty(token)) {
            instance.defaults.headers['Authorization'] = `Bearer ${token}`;
        } else {
            delete instance.defaults.headers['Authorization'];
        }
    }

    private static addInterceptors(instance: AxiosInstance): void {
        // Add request interceptor:
        instance.interceptors.request.use(
            _ => _,
            error => {
                AxiosInstanceProvider.informationMessagesService.Push(new InformationModalData(
                    "Что-то пошло не так...",
                    [
                        "К сожалению, при выполнении операции произошла ошибка! Мы уже работаем над решением данной проблемы. " +
                        "Приносим свои извинения."],
                    () => AxiosInstanceProvider.globalOverlayService.Completed(),
                    true));
            });

        // Add a response interceptor
        instance.interceptors.response.use(
            _ => _,
            error => {
                switch (error.response.status) {
                    case 401: {
                        const logoutOptions = new LogoutOptionsBM(true);
                        AxiosInstanceProvider.informationMessagesService.Push(new InformationModalData(
                            "Предупреждение",
                            [
                                "Ваши авторизационные данные устарели, необходимо войти в систему заново!"],
                            () => {
                                new AccountService().Logout(logoutOptions).then(_ => _);
                            },
                            true));
                        break;
                    }
                    case 404: {
                        throw error;
                    }
                    default: {
                        AxiosInstanceProvider.informationMessagesService.Push(new InformationModalData(
                            "Что-то пошло не так...",
                            [
                                "К сожалению, при выполнении операции произошла ошибка! Мы уже работаем над решением данной проблемы. " +
                                "Приносим свои извинения."],
                            () => AxiosInstanceProvider.globalOverlayService.Completed(),
                            true));
                    }
                }
            });
    }
}
