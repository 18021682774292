import {Route} from "vue-router";
import {CurrentUserService} from "@/core/services/current-user-service/current-user-service";
import {RawLocation} from "vue-router/types/router";
import {IRouteMetadata, routeMetadata} from "@/router/models/route-metadata";
import {RouteNames} from "@/router/route-names";

export class RequireRolesGuard {
    private static readonly currentUserService = new CurrentUserService();

    public static checkAndProcess(to: Route, from: Route, next: (r?: RawLocation) => void): boolean {
        const metadata = routeMetadata(to.meta);
        if (metadata.RequireRoles != null) {
            if (this.currentUserService.roles.filter(r => metadata.RequireRoles!.indexOf(r) >= 0).length === 0) {
                next({name: RouteNames.UnauthorizedRequest});
                return false;
            }
        }

        next();
        return true;
    }
}
