import {Subject, Subscription} from "rxjs";
import {ConversationService} from "@/core/services/conversation/conversation-service";

export interface OnUnreadConversationMessagesCountUpdatingHandler {
    (count: number): void;
}

export class UnreadConversationMessagesCountSynchronizationService {
    private static readonly onUnreadConversationMessagesCountUpdateEventSource = new Subject<number>();
    private static count = 0;

    public static async initialize() {
        this.count = await new ConversationService().readUnreadConversationMessagesCount();
    }

    public static increase(numberToIncrease: number) {
        this.count += numberToIncrease;
        this.onUnreadConversationMessagesCountUpdateEventSource.next(this.count);
    }

    public static decrease(numberToDecrease: number) {
        this.count -= numberToDecrease;
        this.onUnreadConversationMessagesCountUpdateEventSource.next(this.count);
    }

    public static subscribeOnCountUpdating(handler: OnUnreadConversationMessagesCountUpdatingHandler): Subscription {
        handler(this.count);
        return this.onUnreadConversationMessagesCountUpdateEventSource.subscribe(handler);
    }
}
