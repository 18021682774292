import {Defaults} from "@/core/constants/defaults";
import {buildDefault} from "@/core/utils/functional-utils";
import {Guid} from "@/core/types/type-aliases";
import {IArticleComponentData} from "@/core/components.business/article-builder/models/IArticleComponentData";
import {ArticleComponentOptions} from "@/core/components.business/article-builder/models/ArticleComponentOptions";

export enum ArticleComponentTypeEnum {
    Unset = 0,
    Heading = 1,
    Paragraph = 2,
    Image = 3,
    Link = 4,
    Notice = 5,
    QuestionAnswer = 6
}

export enum ArticleBuilderModeEnum {
    Unset = 0,
    View = 1,
    Management = 2
}

export enum ArticleComponentModeEnum {
    Unset = 0,
    View = 1,
    Management = 2
}

export enum ArticleComponentStatusEnum {
    Unset = 0,
    Draft = 1,
    Ready = 2
}

export class HeadingArticleComponentData implements IArticleComponentData {
    public constructor(text: string) {
        this.text = text;
    }

    public text: string;
    public type = ArticleComponentTypeEnum.Heading;

    public clone(): HeadingArticleComponentData {
        return new HeadingArticleComponentData(
            this.text);
    }

    public mergeWith(from: HeadingArticleComponentData): void {
        this.text = from.text;
    }

    public static createDefault() {
        return new HeadingArticleComponentData(Defaults.EmptyString);
    }
}

export class ParagraphArticleComponentData implements IArticleComponentData {
    public constructor(text: string) {
        this.text = text;
    }

    public text: string;
    public type = ArticleComponentTypeEnum.Paragraph;

    public static createDefault() {
        return new ParagraphArticleComponentData(Defaults.EmptyString);
    }

    public clone(): ParagraphArticleComponentData {
        return new ParagraphArticleComponentData(
            this.text);
    }

    public mergeWith(from: ParagraphArticleComponentData): void {
        this.text = from.text;
    }
}

export class ImageArticleComponentData implements IArticleComponentData {
    public constructor(fileId: Guid, imageUrl: string) {
        this.fileId = fileId;
        this.imageUrl = imageUrl;
    }

    public fileId: Guid;
    public imageUrl: string;
    public type = ArticleComponentTypeEnum.Image;

    public static createDefault() {
        return new ImageArticleComponentData(buildDefault<Guid>(), buildDefault<string>());
    }

    public clone(): ImageArticleComponentData {
        return new ImageArticleComponentData(
            this.fileId,
            this.imageUrl);
    }

    public mergeWith(from: ImageArticleComponentData): void {
        this.fileId = from.fileId;
        this.imageUrl = from.imageUrl;
    }
}

export class LinkArticleComponentData implements IArticleComponentData {
    public constructor(name: string, url: string) {
        this.name = name;
        this.url = url;
    }

    public name: string;
    public url: string;
    public type = ArticleComponentTypeEnum.Link;

    public static createDefault() {
        return new LinkArticleComponentData(buildDefault<string>(), buildDefault<string>());
    }

    public clone(): LinkArticleComponentData {
        return new LinkArticleComponentData(
            this.name,
            this.url
        );
    }

    public mergeWith(from: LinkArticleComponentData): void {
        this.name = from.name;
        this.url = from.url;
    }
}

export class NoticeArticleComponentData implements IArticleComponentData {
    public constructor(text: string) {
        this.text = text;
    }

    public text: string;
    public type = ArticleComponentTypeEnum.Notice;

    public clone(): NoticeArticleComponentData {
        return new NoticeArticleComponentData(
            this.text);
    }

    public mergeWith(from: NoticeArticleComponentData): void {
        this.text = from.text;
    }

    public static createDefault() {
        return new NoticeArticleComponentData(buildDefault<string>());
    }
}

export class Article {
    public constructor(name: string, items: ArticleComponentOptions<IArticleComponentData>[], dateTimeCreated: Date) {
        this.name = name;
        this.items = items;
        this.dateTimeCreated = dateTimeCreated;
    }

    public name: string;
    public items: ArticleComponentOptions<IArticleComponentData>[];
    public dateTimeCreated: Date;

    public static createNew(): Article {
        return new Article(
            buildDefault<string>(),
            [],
            new Date()
        );
    }
}
