import {Subject, Subscription} from "rxjs";
import {CurrencyBM} from "@/core/models/metadata/CurrencyBM";

export interface OnClientCurrencyChangedHandler {
    (options: ClientCurrencyOptions): void;
}

export class ClientCurrencySynchronizationService {
    private static $event: ClientCurrencyOptions;
    private static $eventSource = new Subject<ClientCurrencyOptions>();

    public static subscribeOnChange(handler: OnClientCurrencyChangedHandler): Subscription {
        return ClientCurrencySynchronizationService.$eventSource.subscribe(handler);
    }

    public static synchronize(options: ClientCurrencyOptions): void {
        ClientCurrencySynchronizationService.$event = options;
        ClientCurrencySynchronizationService.$eventSource.next(options);
    }

    public static get options(): ClientCurrencyOptions {
        return ClientCurrencySynchronizationService.$event;
    }
}

export class ClientCurrencyOptions {
    public constructor(currency: CurrencyBM) {
        this.Currency = currency;
    }

    public Currency: CurrencyBM;
}
