export class ConversationSubjectSummaryBM {
    public constructor(
        bookingNumber: number,
        bookingObjectNumber: number,
        bookingObjectName: string,
        bookingObjectIconUrl: string) {
        this.BookingNumber = bookingNumber;
        this.BookingObjectNumber = bookingObjectNumber;
        this.BookingObjectName = bookingObjectName;
        this.BookingObjectIconUrl = bookingObjectIconUrl;
    }

    public BookingNumber: number;
    public BookingObjectNumber: number;
    public BookingObjectName: string;
    public BookingObjectIconUrl: string;
}
