export class ArrayHelper {
    public static SortBy<T, TBy>(array: T[], prop: (item: T) => TBy, direction: SortDirection = SortDirection.Ascending): T[] {
        array.sort((a, b) => {
            if (prop(a) < prop(b)) return -1;
            if (prop(a) > prop(b)) return 1;
            return 0;
        });

        if (direction === SortDirection.Descending) array.reverse();
        return array;
    }

    public static SplitIntoChunks<T>(data: T[], chunkSize: number) {
        const numberOfChunks = Math.ceil(data.length / chunkSize);

        const result = [];
        for (let i = numberOfChunks; i > 0; i--) {
            result.push(data.splice(0, Math.ceil(data.length / i)));
        }

        return result;
    }

    public static AllAreEqual<T>(data: T[]) {
        return data.every(element => element === data[0]);
    }

    public static Sum(data: number[]) {
        return data.reduce((a, b) => a + b, 0);
    }

    public static Take<T>(data: T[], count: number) {
        return data.filter((item, index) => index < count);
    }
}

export enum SortDirection {
    Ascending,
    Descending
}
