import {Locale_EN, SupportedLocale} from "@/core/constants/supported-locales";
import i18n from "@/i18n"
import {GlobalOverlayService} from "@/core/services.ui/global-overlay-service";

export class LocalizationService {
    private readonly CurrentLocaleLocalStorageKeyName = "CurrentUserLocale";

    public Setup(): void {
        const locale = localStorage.getItem(this.CurrentLocaleLocalStorageKeyName) as SupportedLocale;
        if (locale != null) {
            i18n.locale = locale;
        } else {
            i18n.locale = locale;
            localStorage.setItem(this.CurrentLocaleLocalStorageKeyName, locale);
        }
    }

    public GetCurrentLocale(): SupportedLocale {
        const locale = localStorage.getItem(this.CurrentLocaleLocalStorageKeyName) as SupportedLocale;
        if (locale == null) return Locale_EN;
        return locale;
    }

    public ChangeLocale(locale: SupportedLocale, now = false): void {
        if (now) i18n.locale = locale;
        localStorage.setItem(this.CurrentLocaleLocalStorageKeyName, locale);
    }
}
